import {createSelector} from 'reselect';
import {getLoader, getLoginForm, selectVerifyInfo} from '../../App/selectors';

const getClubs = state =>
  selectLoginForm(state)
    .getIn(['allClubs'])
    .toJS();
const clubName = state => selectLoginForm(state).getIn(['clubName']);
const clubId = state => selectLoginForm(state).getIn(['clubId']);
const getRegion = state => selectLoginForm(state).getIn(['region']);

const getUsername = state => selectVerifyInfo(state).username;
const getPassword = state => selectVerifyInfo(state).password;
const getIfPin = state => selectVerifyInfo(state).ifPin;
const getPinCode = state => selectVerifyInfo(state).pinCode;

const isClubActive = ({ isDspActive, externalId }) => isDspActive && externalId;
const isClubInRegion = (region, { region: clubRegion }) => clubRegion && clubRegion.name === region;

const clubsByRegion = (clubs, region) => {
  if (!region) {
    return clubs.filter(club => isClubActive(club));
  }
  return clubs.filter(club => isClubActive(club) && isClubInRegion(region, club));
};

export const selectAllCubs = createSelector([getClubs, getRegion], clubsByRegion);
export const selectSelectedRegion = createSelector([getRegion], region => region);

export const selectClubName = createSelector([clubName], club => club);
export const selectClubId = createSelector([clubId], clubId => clubId);
export const selectLoader = createSelector([getLoader], loader => loader);
export const selectLoginForm = createSelector([getLoginForm], login => login);

export const selectUsername = createSelector([getUsername], username => username);
export const selectPassword = createSelector([getPassword], password => password);
export const selectIfPin = createSelector([getIfPin], ifPin => ifPin);
export const selectPinCode = createSelector([getPinCode], pin => pin);
